<template>
  <div id="ComplainScreen">
    <v-container>
      <v-row>
        <v-col class="col-12 col-lg-9 pa-5">
          <complain-list :getComplainsFilter="getComplains"></complain-list>
        </v-col>
        <v-col class="col-12 col-lg-3 pa-5">
          <v-card>
            <v-app-bar dense color="transparent">
              <v-toolbar-title>
                <h5>Masukkan Pencarian</h5>
              </v-toolbar-title>
            </v-app-bar>
            <v-card-text>
              <v-form v-model="valid" ref="form" lazy-validation>
                <v-menu
                  v-model="menuFromDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="fromDate"
                      label="Dari Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    @input="menuFromDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="menuToDate"
                  :close-on-content-click="false"
                  :nudge-top="20"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      dense
                      outlined
                      v-model="toDate"
                      label="Sampai Tanggal"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    @input="menuToDate = false"
                  ></v-date-picker>
                </v-menu>
                <v-text-field
                  dense
                  outlined
                  label="Nomor PO"
                  v-model="noPo"
                  clearable
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn block color="primary" @click="getComplainData">Cari</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ComplainList from "@/components/Widget/supplier/ComplainList";
import globalMixin from "@/mixins/globalMixin.js";
import ComplainService from "@/services/ComplainService";

export default {
  components: {
    ComplainList
  },
  mixins: [globalMixin],

  data() {
    return {
      fromDate: "",
      toDate: "",
      menuFromDate: false,
      menuToDate: false,
      noPo: "",
      valid: true,
      getComplains: []
    };
  },

  mounted() {
    this.getAll();
  },

  methods: {
    getAll() {
      ComplainService.getAll()
        .then(response => {
          this.getComplains = response.data.complains;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        });
    },

    getComplainData() {
      this.$root.$loaderModal.start("Loading...");

      const payload = {
        start_date: this.fromDate,
        end_date: this.toDate,
        invoice_number: this.noPo
      };

      ComplainService.getBySearch(payload)
        .then(response => {
          if (response.data.status === 0) {
            this.$tostini({
              message: response.data.message,
              type: "error"
            });
          } else {
            this.getComplains = response.data.complains;
          }
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    }
  }
};
</script>
