<template>
  <div>
    <v-card>
      <v-app-bar dense color="transparent">
        <v-toolbar-title>
          <h5>Daftar Komplain</h5>
        </v-toolbar-title>
      </v-app-bar>

      <v-card-text class="pa-0">
        <template>
          <v-data-table
            :headers="headers"
            :items="getComplainsFilter"
            class="elevation-0"
            mobile-breakpoint="0"
          >
            <template slot="item" slot-scope="props">
              <tr>
                <td class="detailLink" @click="getDetail(props.item.id)">
                  {{ props.item.po_number }}
                </td>
                <td>{{ props.item.store_name }}</td>
                <td>{{ getDate(props.item.order_date) }}</td>
                <td>{{ props.item.total_item }}</td>
                <td>{{ formatRupiah(props.item.total_price) }}</td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="1000">
      <v-card class="mx-auto" outlined>
        <v-app-bar dense color="transparent">
          <v-toolbar-title>
            <h5>Nomor PO: {{ dataDetail.poNumber }}</h5>
          </v-toolbar-title>
        </v-app-bar>

        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col class="col-3">
                <div class="body-1 text--primary">
                  Nomor Invoice
                </div>
              </v-col>
              <v-col class="col-9 col-md-3">
                <div class="body-1 text--primary">
                  :&ensp; {{ dataDetail.invoiceNumber }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                <div class="body-1 text--primary">
                  Tanggal Pemesanan
                </div>
              </v-col>
              <v-col class="col-9 col-md-3">
                <div class="body-1 text--primary">
                  :&ensp; {{ getDate(dataDetail.orderDate) }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                <div class="body-1 text--primary">
                  Nomor Telepon
                </div>
              </v-col>
              <v-col class="col-9 col-md-3">
                <div class="body-1 text--primary">
                  :&ensp; {{ dataDetail.phoneNumber }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="col-3">
                <div class="body-1 text--primary">
                  Alamat
                </div>
              </v-col>
              <v-col class="col-9">
                <div class="body-1 text--primary">
                  :&ensp; {{ dataDetail.address }}
                </div>
              </v-col>
            </v-row>

            <v-card class="my-10">
              <v-app-bar dense color="transparent">
                <v-toolbar-title>
                  <h5>Daftar Produk</h5>
                </v-toolbar-title>
              </v-app-bar>

              <v-card-text class="pa-0">
                <template>
                  <v-data-table
                    :headers="headersDetail"
                    :items="dataDetail.complainDetail"
                    class="elevation-0"
                    mobile-breakpoint="0"
                  >
                    <template slot="item" slot-scope="props">
                      <tr>
                        <td>{{ props.item.product_name }}</td>
                        <td>{{ props.item.product_code }}</td>
                        <td>{{ formatAmount(props.item.quantity_order) }}</td>
                        <td>
                          {{ formatRupiah(props.item.price_order) }}
                        </td>
                        <td>
                          {{ formatRupiah(props.item.subtotal) }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>

            <v-row>
              <v-col class="col-6 col-md-9">
                <div class="text-h6 text--primary text-end">
                  Jumlah Barang :
                </div>
              </v-col>
              <v-col class="col-6 col-md-3">
                <div class="text-h6 text--primary text-end">
                  {{ formatAmount(dataDetail.totalItem) }}
                </div>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-6 col-md-9">
                <div class="text-h6 text--primary text-end">
                  Total :
                </div>
              </v-col>
              <v-col class="col-6 col-md-3">
                <div class="text-h6 text--primary text-end">
                  {{ formatRupiah(dataDetail.grandTotal) }}
                </div>
              </v-col>
            </v-row>

            <v-row class="mt-10">
              <v-col class="col-12 body-1 text--primary"
                >Komplain Diajukan Pada Tanggal
                {{ dataDetail.complainDate }}</v-col
              >
              <v-col class="col-12">
                <v-textarea
                  v-model="dataDetail.complainMessage"
                  disabled
                  outlined
                  no-resize
                  clearable
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="col-12 body-1 text--primary"
                >Solusi dari Supplier</v-col
              >
              <v-col class="col-12">
                <v-textarea
                  v-model="dataDetail.solutionMessage"
                  outlined
                  counter
                  no-resize
                  clearable
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container>
              <div class="d-flex justify-end mt-3">
                <v-btn
                  color="success"
                  @click="approveComplain()"
                  :loading="loadingApprove"
                  :disabled="disabledApprove"
                  >Submit</v-btn
                >
              </div>
            </v-container>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import globalMixin from "@/mixins/globalMixin.js";
import ComplainService from "@/services/ComplainService";

export default {
  props: ["getComplainsFilter"],
  mixins: [globalMixin],

  data() {
    return {
      id: null,
      dialog: false,
      loadingApprove: false,
      disabledApprove: false,
      complains: this.getComplainsFilter,
      headers: [
        { text: "Nomor PO", sortable: false },
        { text: "Nama Toko Bangunan", sortable: false },
        { text: "Tanggal Pemesanan", sortable: false },
        { text: "Jumlah Barang", sortable: false },
        { text: "Total", sortable: false }
      ],
      headersDetail: [
        { text: "Nama Produk", sortable: false },
        { text: "SKU", sortable: false },
        { text: "Jumlah Produk", sortable: false },
        { text: "Harga Satuan", sortable: false },
        { text: "Sub Total", sortable: false }
      ],
      dataDetail: {
        poNumber: "",
        invoiceNumber: "",
        orderDate: "",
        phoneNumber: "",
        address: "",
        complainDetail: [],
        totalItem: "",
        grandTotal: "",
        complainDate: "",
        complainMessage: "",
        solutionMessage: ""
      }
    };
  },
  methods: {
    getDetail(id) {
      this.$root.$loaderModal.start("Loading...");

      this.id = id;

      const payload = {
        id: id
      };

      ComplainService.getDetail(payload)
        .then(response => {
          this.dataDetail.poNumber = response.data.complain.po_number;
          this.dataDetail.invoiceNumber = response.data.complain.invoice_number;
          this.dataDetail.orderDate = response.data.complain.order_date;
          this.dataDetail.phoneNumber = response.data.complain.phone_number;
          this.dataDetail.address = response.data.complain.address;
          this.dataDetail.complainDetail = response.data.complain.details;
          this.dataDetail.totalItem = response.data.complain.total_item;
          this.dataDetail.grandTotal = response.data.complain.total_price;

          this.dataDetail.complainDate = response.data.complain.complain_date;
          this.dataDetail.complainMessage =
            response.data.complain.complain_message;
          this.dataDetail.solutionMessage =
            response.data.complain.solution_message != null
              ? response.data.complain.solution_message
              : "";

          this.dialog = true;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.$root.$loaderModal.hide();
        });
    },
    approveComplain() {
      this.loadingApprove = true;
      this.disabledApprove = true;

      if (this.dataDetail.solutionMessage === "") {
        this.loadingApprove = false;
        this.disabledApprove = false;

        this.$tostini({
          message: "Solusi Wajib Diisi!",
          type: "error"
        });

        return;
      }

      const payload = {
        id: this.id,
        solution_message: this.dataDetail.solutionMessage
      };

      ComplainService.approve(payload)
        .then(response => {
          this.$tostini({
            message: response.data.message,
            type: "success"
          });

          ComplainService.getAll()
            .then(response => {
              this.getComplainsFilter = response.data.complains;
            })
            .catch(() => {
              this.$tostini({
                message: "Terjadi Kesalahan!",
                type: "error"
              });
            });

          this.dialog = false;
        })
        .catch(() => {
          this.$tostini({
            message: "Terjadi Kesalahan!",
            type: "error"
          });
        })
        .finally(() => {
          this.loadingApprove = false;
          this.disabledApprove = false;
        });
    }
  }
};
</script>
