import api from "@/services/ApiService";

export default {
  getAll() {
    return api().post("/supplier/complain");
  },
  getDetail(id) {
    return api().post(`/supplier/complain/detail`, id);
  },
  getBySearch(params) {
    return api().post(`/supplier/complain`, params);
  },
  approve(params) {
    return api().post(`/supplier/complain/submit`, params);
  }
};
